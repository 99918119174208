<template>
  <div id="logs" class="col-12 padding-content">
    <v-row no-gutters>
      <v-col cols="12" class="main-content">
        <v-row class="table-view" no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <v-data-table 
                :headers="headers"
                :items="desserts"
                :options.sync="options"
                :items-per-page="pageSize"
                :server-items-length="totalDesserts"
                :search="search"
                @pagination="updatePagination"
                no-data-text="No Data"
                :loading="loading"
                :footer-props="footer"
              >
                <template v-slot:top>
                  <v-row no-gutters>
                    <v-col cols="12" id="search-bar">
                      <v-text-field
                        v-model="search"
                        placeholder="Search Logs"
                        class="search-input"
                        append-icon="fas fa-search"
                        rounded
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-spacer />
                  </v-row>
                </template>
                <template v-slot:[`item.no`]="{ index }">
                  {{ index + 1 + (currentPage - 1) * pageSize }}
                </template>
                <template v-slot:[`item.device_id`]="{ item }">
                  {{ item.device_id }}
                </template>
              </v-data-table>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss" src="../../assets/css/logs.scss"></style>
<script>
import { footerProps } from "../../constants";
import { logService } from "../../services";

export default {
  data() {
    return {
      search: "",
      logs: [],
      headers: [
        {
          text: "No",
          value: "no",
          width: 100,
          sortable: false,
          filterable: false,
        },
        {
          text: "Time",
          align: "start",
          value: "created_at",
          width: 300,
        },
        {
          text: "IP",
          align: "start",
          value: "ip",
          width: 150,
        },
        {
          text: "Notification ID",
          align: "start",
          value: "id",
        },
        {
          text: "Device",
          align: "start",
          value: "device_id",
        },
        {
          text: "Event",
          align: "start",
          value: "event",
        },
        {
          text: "Detail",
          align: "start",
          value: "detail",
        },
      ],
      currentPage: 1,
      pageSize: 14,
      footer: {},
      total: 0,
      totalRecords: 0,
      totalDesserts: 0,
      desserts: [],
      loading: false,
      options: {},
      awaitingSearch: false
    };
  },
   watch: {
    options: {
      handler () {
        this.getDataFromApi();
      },
      deep: true,
    },
    search: function() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getDataFromApi();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    }
  },
  created() {
    this.getDimensions();
    this.footer = Object.assign({}, this.footer, footerProps);
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  methods: {
    getDataFromApi () {
      this.loading = true
      this.fakeApiCall().then(data => {
        this.desserts = data.items
        this.totalDesserts = data.total
        this.loading = false
      })
    },
    async fakeApiCall () {
      let body = `skip=${this.options.page && this.options.itemsPerPage ? Math.floor((this.options.page - 1) * this.options.itemsPerPage) : 0}
        &take=${this.options.itemsPerPage}`;
      if (this.search) {
        body += `&search=${this.search}`;
      }
      if (this.options.sortBy.length > 0 && this.options.sortDesc.length > 0) {
        body += `&sort=${this.options.sortBy[0]}&order=${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`
      } else {
        body += `&sort=id&order=DESC`
      }
      body += `&type=lighting_failure`
      await logService
      .getListLogs(body)
        .then((res) => {
          if (res?.data?.data) {
            this.total = Math.ceil((res.data.data.total || 0) / this.pageSize);
            this.logs = res.data.data.data;
            this.totalRecords = res.data.data.total;
          }
        })
      return new Promise((resolve) => {
        let items = this.logs;
        const total = this.totalRecords
        setTimeout(() => {
          resolve({
            items,
            total,
          })
        }, 1000)
      })
    },
    getDimensions() {
      this.pageSize = 14;
       if (window.innerWidth == 1180 || window.innerHeight == 820) {
        this.pageSize = 15;
      }
      if (window.innerWidth == 1920 && window.innerHeight <= 960) {
        this.pageSize = 12;
      }
    },
    replaceTypeLog(type) {
      return type[0].toUpperCase() + type.slice(1).replaceAll("_", " ");
    },
    updatePagination(pagination) {
      this.currentPage = pagination.page;
      this.pageSize = pagination.itemsPerPage;
      this.$set(
        this.footer,
        "pageText",
        `${this.currentPage} of ${this.total}`
      );
    },
  },
};
</script>
